import { HTMLInputTypeAttribute } from 'react';
import { InputField } from './InputFields';

interface InputFormProps {
    placeholder?: string;
    disabled?: boolean;
    type?: HTMLInputTypeAttribute | undefined;
    value: string;
    name?: string;
    onChange: (e) => void;
    className?: string;
    maxLength?: number;
    formik?: any;
    PrefixIcon?: any;
    formikName?: string;
    Label: string;
    labelClassName?: string;
    labelContainerClassName?: string;
    inputContainerClassName?: string;
    showError?: boolean;
    containerClassName: string;
    required?: boolean;
}

const InputForm: React.FC<InputFormProps> = ({
    value,
    name = '',
    type = 'text',
    onChange,
    placeholder,
    className,
    disabled,
    maxLength,
    formik,
    PrefixIcon,
    formikName,
    Label,
    labelClassName,
    labelContainerClassName,
    inputContainerClassName,
    showError = true,
    containerClassName,
    required = true,
}) => (
    <div className={`mt-1 ${containerClassName}`}>
        <div className={`col-lg-4 col-form-label py-0 input-height ${labelContainerClassName}`}>
            {formikName && formik ? (
                formik.values[formikName].length !== 0 ? (
                    <label className={`${required ? 'required' : ''} required fw-bold fs-9  ${labelClassName}`}>
                        {Label}
                    </label>
                ) : (
                    ''
                )
            ) : (
                <label className={`${required ? 'required' : ''} fw-bold fs-9  ${labelClassName}`}>{Label}</label>
            )}
        </div>

        <div
            className={`form-control form-control-solid ${inputContainerClassName} ${formik && formikName && !formik.errors[formikName] ? '' : ''}`}
        >
            {PrefixIcon && <img src={PrefixIcon} alt="form-icon" className="icon-style" />}

            <InputField
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                value={formikName && formik ? formik.values[formikName] : value}
                name={formikName && formik ? undefined : name}
                className={className}
                maxLength={maxLength}
                onChange={formikName && formik ? formik.handleChange : onChange}
                {...formik?.getFieldProps?.(formikName)}
            />
        </div>

        <div className="fv-plugins-message-container input-height">
            {showError && formikName && formik && (
                <div className="fv-help-block fs-9 text-danger">
                    {formik.touched[formikName] && formik.errors[formikName] && formik.errors[formikName]}
                </div>
            )}
        </div>
    </div>
);

export { InputForm };
