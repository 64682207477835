import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';

import { useGetUserBankDetails, useRequestOnboardingStepBankDetails } from '../hooks/useUser';
import { infoBlue } from 'core/assets/icons';

const Schema = Yup.object().shape({
    bankAccountNumber: Yup.string()
        .matches(/^[0-9]{13}$/, 'Invalid account number')
        .max(13, 'Account number must be at most 13 characters long')
        .min(13, 'Account number must be at most 13 characters long')
        .required('Account number is required'),

    accountType: Yup.string()
        .required('Account Type is required')
        .max(50, 'Text field must be at most 50 characters long'),
    accountName: Yup.string()
        .required('Account Name is required')
        .max(50, 'Text field must be at most 50 characters long'),
});

const initialValues = {
    daliyTransactionLimit: '',
    bankAccountNumber: '',
    bankName: 'Central Bank Of Nigeria (CBN)',
    accountType: '',
    accountName: '',
};

interface Step1Props {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    setTable: React.Dispatch<React.SetStateAction<any>>;
    setStepTable: React.Dispatch<React.SetStateAction<any>>;
    stepTable: any;
}

const StepFour: React.FC<Step1Props> = ({
    handleNextStep,
    handlePreviousStep,
    setFormData,
    setTable,
    setStepTable,
    stepTable,
}) => {
    const [editIndex, setEditIndex] = useState<any>(-1);
    const [proceed, setProceed] = useState<Boolean>(false);

    const formRef = useRef<any>(null);

    const { data, mutate, isLoading } = useRequestOnboardingStepBankDetails();

    const {
        data: bankDetails,
        mutate: mutateBankDetails,
        isLoading: mutateBankDetailsLoading,
    } = useGetUserBankDetails();

    useEffect(() => {
        if (data) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
            formik.resetForm();
            setProceed(true);
        }
    }, [mutate, data, mutateBankDetails, bankDetails]);

    const onNext = () => {
        handleNextStep();
    };

    const [show, setShow] = useState<boolean>(false);

    const SuccessModal = () => {
        return (
            <Modal
                dialogClassName=" m-auto px-7 modal-card-success"
                aria-hidden="true"
                show={show}
                onHide={() => setShow(false)}
                animation
                centered
                size="lg"
                backdrop={'static'}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <div className="">
                        <div className=" d-flex flex-column align-items-center ">
                            <div className="mt-5 mb-3">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                                        stroke="#61CD85"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>

                            <div className="email-verification-container mb-3">
                                <span className="email-verification-text">Successful!</span>
                            </div>

                            <div className="email-verification-container mb-5">
                                <span className="email-verification-body">
                                    You have successfully added the bank account. To add more accounts, please click on
                                    the “add more accounts” button
                                </span>
                            </div>
                        </div>

                        <div className=" d-flex justify-content-between align-items-center mt-3 pe-9 ps-9 ">
                            <button className="otp-button-addmore" onClick={() => setShow(false)}>
                                <span className="">Add more</span>
                            </button>

                            <button className="otp-button " onClick={() => onNext()}>
                                <span className="otp-button-text">Proceed</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            setConfirm(false);
            mutate(
                {
                    bankName: values.bankName,
                    accountNumber: values.bankAccountNumber,
                    dailyLimit: '',
                    accountType: {
                        code: '00000',
                        description: values.accountType,
                    },
                    accountName: values.accountName,
                },
                {
                    onSuccess: (res: any) => {
                        setShow(true);
                        setTable(true);
                        setProceed(true);
                        formik.resetForm();

                        setStepTable(stepTable + 1);
                    },
                },
            );
        },
    });

    const [showConfirm, setConfirm] = useState<any>(false);

    const confrimModal = () => {
        return (
            <Modal
                dialogClassName="  m-auto px-5 modal-card"
                aria-hidden="true"
                show={showConfirm}
                onHide={() => setConfirm(false)}
                animation
                centered
                size="lg"
                backdrop={'static'}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <>
                        <div className="m-3 mx-15">
                            <div className=" d-flex flex-column align-items-center ">
                                <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                                <span className="text-center fs-base text-dark">Are you sure you want to submit?</span>
                            </div>
                            <div className="d-flex justify-content-between mt-10">
                                <button
                                    id="kt_password_cancel "
                                    type="button"
                                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                                    onClick={() => setConfirm(false)}
                                >
                                    <span className="indicator-label fs-base ">Cancel</span>
                                </button>
                                <button
                                    className=" btn btn-success h-44 w-110 center-all"
                                    type="button"
                                    onClick={() => formik.handleSubmit()}
                                >
                                    <span className="indicator-label fs-base ">Submit</span>
                                </button>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <div ref={formRef}>
                <div className="row">
                    <div className="col-lg-6 col-form-label py-0 input-height">
                        {formik.values.bankName.length !== 0 && (
                            <label className="row required fw-bold fs-9">Bank Name</label>
                        )}
                    </div>

                    <div className="form-control form-control-solid  ">
                        <input
                            type="text"
                            placeholder="Central Bank Of Nigeria (CBN)  "
                            className={'no-outline ps-4 text-dark fs-8'}
                            {...formik.getFieldProps('bankName')}
                            disabled
                        />
                    </div>

                    <div className="fv-plugins-message-container input-height">
                        <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                            {formik.touched.bankName && formik.errors.bankName && formik.errors.bankName}
                        </div>
                    </div>
                </div>

                <div className="row mt-7">
                    <div className="col-lg-12 col-form-label py-0 input-height">
                        {formik.values.bankAccountNumber.length !== 0 && (
                            <label className=" row required fw-bold fs-9">Bank Account Number</label>
                        )}
                    </div>

                    <div className="form-control form-control-solid  ">
                        <input
                            type="text"
                            maxLength={13}
                            placeholder="Bank Account Number *   "
                            className={'no-outline ps-4 text-dark fs-8'}
                            {...formik.getFieldProps('bankAccountNumber')}
                        />
                    </div>

                    <div className="fv-plugins-message-container input-height">
                        <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                            {formik.touched.bankAccountNumber &&
                                formik.errors.bankAccountNumber &&
                                formik.errors.bankAccountNumber}
                        </div>
                    </div>
                </div>

                <div className="row mt-7">
                    <div className="col-lg-12 col-form-label py-0 input-height">
                        {formik.values.accountType.length !== 0 && (
                            <label className="required fw-bold fs-9">Account Classification</label>
                        )}
                    </div>

                    <div className="form-control form-control-solid  ">
                        <select
                            {...formik.getFieldProps('accountType')}
                            className={`no-outline ps-4 fs-8 ${
                                formik.values.accountType !== '' ? 'text-dark' : 'text-muted'
                            } `}
                        >
                            <option value="">Account classification</option>
                            <option value="Federation Account">Federation Account (FAAC)</option>

                            <option value="Special Accounts">Special Accounts</option>

                            <option value=" Revolving Account">Revolving Account</option>

                            <option value="TSA-Sub Account">TSA-Sub Account</option>
                            <option value="Donor/Project Account">Donor/Project Account</option>
                        </select>
                    </div>

                    <div className="fv-plugins-message-container input-height">
                        <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                            {formik.touched.accountType && formik.errors.accountType && formik.errors.accountType}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-form-label py-0 input-height">
                        {formik.values.accountName.length !== 0 && (
                            <label className=" row required fw-bold fs-9">Account Name</label>
                        )}
                    </div>

                    <div className="form-control form-control-solid  ">
                        <input
                            type="text"
                            placeholder="Account Name * "
                            className={'no-outline ps-4 text-dark fs-8'}
                            {...formik.getFieldProps('accountName')}
                        />
                    </div>

                    <div className="fv-plugins-message-container input-height">
                        <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                            {formik.touched.accountName && formik.errors.accountName && formik.errors.accountName}
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                    className={`row mt-9  otp-button user-btn ${
                        !(formik.isValid && formik.dirty) ? 'add-more-account-root-cancle' : 'add-more-account-root '
                    } text-white px-7 py-2`}
                    onClick={(e) => setConfirm(true)}
                >
                    Save
                </button>

                <nav className={`nav navbar-fixed-signup-bottom `} id="nav">
                    <div className="nav-center-signup">
                        {/* Nav header */}
                        <div className="nav-header-signup">
                            <button className="nav-btn-back nav-back-text" id="nav-btn" onClick={handlePreviousStep}>
                                <i className="fa fa-solid fa-arrow-left"></i>{' '}
                                <span style={{ marginLeft: '0.2rem' }}>back</span>
                            </button>{' '}
                            {/* <button className="nav-btn-Proceed" id="nav-btn">
                          Proceed
                        </button> */}
                            <button
                                id="kt_password_cancel "
                                className={`btn nav-btn-Proceed ${
                                    !proceed ? 'btn-secondary' : 'btn-success'
                                } text-white px-7 py-2`}
                                disabled={!proceed}
                                onClick={handleNextStep}
                            >
                                {!isLoading && <span className="indicator-label">Proceed</span>}
                                {isLoading && (
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </nav>
            </div>

            {SuccessModal()}
            {confrimModal()}
        </>
    );
};

export { StepFour };
