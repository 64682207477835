import axios, { AxiosRequestConfig } from "axios";
import { FetchAndUnmask } from "core/helpers";
import UAParser from "ua-parser-js";
import { baseUrl, baseUrlStepThree, baseUrlStepTwo } from "./constants";

// Get device information
export const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    browser: result.browser.name,
    browserVersion: result.browser.version,
    os: result.os.name,
    osVersion: result.os.version,
    device: result.device.model || "Desktop",
  };
};

// Get location using geolocation
const getLocation = (): Promise<{ longitude: number; latitude: number }> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        }),
        (error) => reject(error),
        { timeout: 10000 }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

// Generate JWT headers
export function getJWTHeader(user: any): Record<string, string> {
  return { Authorization: `Bearer ${user.token}` };
}

export function getJWTHeaderPreLogin(token: string): Record<string, string> {
  return { Authorization: `Bearer ${token}` };
}

// Fetch token securely
const token: string | null = FetchAndUnmask("token");

// Axios instance configuration
const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({ baseURL });

  const addHeaders:any = async (config: AxiosRequestConfig) => {
    const deviceInfo = getDeviceInfo();
    let longitude = "";
    let latitude = "";

    // Attempt to get location
    try {
      const location = await getLocation();
      longitude = location.longitude.toString();
      latitude = location.latitude.toString();
    } catch (error) {
      //console.warn("Error fetching location:", error);
    }

    config.headers = {
      ...config.headers,
      LONG: longitude,
      DEVICE: deviceInfo.device,
      IP: localStorage.getItem('ipAddress'),
      LAT: latitude,
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    return config;
  };

  // Request interceptor to add headers
  instance.interceptors.request.use(addHeaders, (error) => Promise.reject(error));

  // Response interceptor
  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
  );

  return instance;
};

// Create instances for different base URLs
const axiosInstance = createAxiosInstance(baseUrl);
const axiosInstance2 = createAxiosInstance(baseUrlStepTwo);
const axiosInstance3 = createAxiosInstance(baseUrlStepThree);

export { axiosInstance, axiosInstance2, axiosInstance3 };
