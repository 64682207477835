export const baseUrl = "https://api-web.fgntreasury.gov.ng/";

export const baseUrlStepTwo = "https://api-web.fgntreasury.gov.ng/";
export const baseUrlStepThree = "https://api-web.fgntreasury.gov.ng/";

// export const baseUrl = "https://api-qa.fgntreasury.gov.ng/";

// export const baseUrlStepTwo = "https://api-qa.fgntreasury.gov.ng/";
// export const baseUrlStepThree = "https://api-qa.fgntreasury.gov.ng/";

export const baseImageUrl = `${baseUrl}/images`;
