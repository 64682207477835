import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {  phone } from 'core/assets/imgs';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import { Modal } from 'react-bootstrap';
import { envelope, infoBlue } from 'core/assets/icons';
import { useRequestOnboardingStepOne } from '../hooks/useUser';
import { phoneRegExp } from 'core/helpers/RegexHelpers';
import { FetchAndUnmask, MaskAndSave } from 'core/helpers';


const Schema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required').max(50, 'Text field must be at most 50 characters long'),
    lastName: Yup.string().required('Last Name is required').max(50, 'Text field must be at most 50 characters long'),
    email: Yup.string().email('Wrong email format').required('Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required').matches(phoneRegExp, 'Phone number is not valid'),
});
let stepJson: any = [];
let stepOneJson;

interface Step1Props {
    handleNextStep: () => void;
}

const StepOne: React.FC<Step1Props> = ({ handleNextStep }) => {
    const { data, mutate, isLoading } = useRequestOnboardingStepOne();
    const [checked, setChecked] = useState(false);

    var names: any = [];

    useEffect(() => {
        stepJson = FetchAndUnmask('names');

        stepOneJson = stepJson !== null ? JSON.parse(stepJson) : null;
    }, [handleNextStep, data, names]);

    const initialValues = {
        firstName: stepOneJson ? String(stepOneJson['0']?.firstName) : '',
        lastName: stepOneJson ? String(stepOneJson['0']?.lastName) : '',
        phoneNumber: stepOneJson ? String(stepOneJson['0']?.phoneNumber) : '',
        email: stepOneJson ? String(stepOneJson['0']?.email) : '',
        login: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            setConfirm(false);

            values.login = values.email;

            names[0] = values;

            MaskAndSave('names', JSON.stringify(names));

            mutate(values, {
                onSuccess: (res: any) => {
                    handleNextStep();
                },
                onError: (error) => {},
            });
        },
    });

    const termsCondtion = () => {
        setChecked(!checked);
    };

    const [showConfirm, setConfirm] = useState<any>(false);

    const confrimModal = () => {
        return (
            <Modal
                dialogClassName="  m-auto px-5 modal-card"
                aria-hidden="true"
                show={showConfirm}
                onHide={() => setConfirm(false)}
                animation
                centered
                size="lg"
                backdrop={'static'}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <>
                        <div className="m-3 mx-15">
                            <div className=" d-flex flex-column align-items-center ">
                                <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                                <span className="text-center fs-base text-dark">Are you sure you want to submit?</span>
                            </div>
                            <div className="d-flex justify-content-between mt-10">
                                <button
                                    id="kt_password_cancel "
                                    type="button"
                                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                                    onClick={() => setConfirm(false)}
                                >
                                    <span className="indicator-label fs-base ">Cancel</span>
                                </button>
                                <button
                                    className=" btn btn-success h-44 w-110 center-all"
                                    type="button"
                                    onClick={() => formik.handleSubmit()}
                                >
                                    <span className="indicator-label fs-base ">Submit</span>
                                </button>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                    {formik.values.firstName.length !== 0 && (
                        <label className="required fw-bold fs-9">First Name * </label>
                    )}
                </div>

                <div className="form-control form-control-solid  ">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.75586 20.0308C2.54865 20.3895 2.67143 20.8482 3.03009 21.0554C3.38875 21.2626 3.84747 21.1398 4.05468 20.7812L2.75586 20.0308ZM20.9455 20.7813C21.1527 21.14 21.6115 21.2628 21.9701 21.0556C22.3288 20.8484 22.4516 20.3897 22.2444 20.031L20.9455 20.7813ZM4.05468 20.7812C4.91093 19.2991 6.14212 18.0684 7.62457 17.2129L6.87476 15.9137C5.16433 16.9009 3.74379 18.3208 2.75586 20.0308L4.05468 20.7812ZM7.62457 17.2129C9.10702 16.3573 10.7885 15.9068 12.5002 15.9069L12.5002 14.4069C10.5253 14.4068 8.5852 14.9265 6.87476 15.9137L7.62457 17.2129ZM12.5002 15.9069C14.2118 15.9069 15.8933 16.3573 17.3757 17.213L18.1255 15.9138C16.4151 14.9266 14.475 14.4069 12.5002 14.4069L12.5002 15.9069ZM17.3757 17.213C18.8581 18.0686 20.0893 19.2993 20.9455 20.7813L22.2444 20.031C21.2565 18.321 19.836 16.901 18.1255 15.9138L17.3757 17.213ZM17.7501 9.15686C17.7501 12.0564 15.3996 14.4069 12.5002 14.4069V15.9069C16.2281 15.9069 19.2501 12.8848 19.2501 9.15686H17.7501ZM12.5002 14.4069C9.60065 14.4069 7.25005 12.0563 7.25005 9.15686H5.75005C5.75005 12.8848 8.77226 15.9069 12.5002 15.9069V14.4069ZM7.25005 9.15686C7.25005 6.25736 9.60056 3.90686 12.5001 3.90686V2.40686C8.77213 2.40686 5.75005 5.42894 5.75005 9.15686H7.25005ZM12.5001 3.90686C15.3995 3.90686 17.7501 6.25737 17.7501 9.15686H19.2501C19.2501 5.42894 16.228 2.40686 12.5001 2.40686V3.90686Z"
                            fill="#CCCCCC"
                        />
                    </svg>

                    <input
                        type="text"
                        placeholder="First Name * "
                        className={'no-outline ps-4 text-dark fs-8'}
                        {...formik.getFieldProps('firstName')}
                    />
                </div>

                <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                        {formik.touched.firstName && formik.errors.firstName && formik.errors.firstName}
                    </div>
                </div>
            </div>

            <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                    {formik.values.lastName.length !== 0 && <label className="required fw-bold fs-9">Last Name</label>}
                </div>

                <div className="form-control form-control-solid  ">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.75586 20.0308C2.54865 20.3895 2.67143 20.8482 3.03009 21.0554C3.38875 21.2626 3.84747 21.1398 4.05468 20.7812L2.75586 20.0308ZM20.9455 20.7813C21.1527 21.14 21.6115 21.2628 21.9701 21.0556C22.3288 20.8484 22.4516 20.3897 22.2444 20.031L20.9455 20.7813ZM4.05468 20.7812C4.91093 19.2991 6.14212 18.0684 7.62457 17.2129L6.87476 15.9137C5.16433 16.9009 3.74379 18.3208 2.75586 20.0308L4.05468 20.7812ZM7.62457 17.2129C9.10702 16.3573 10.7885 15.9068 12.5002 15.9069L12.5002 14.4069C10.5253 14.4068 8.5852 14.9265 6.87476 15.9137L7.62457 17.2129ZM12.5002 15.9069C14.2118 15.9069 15.8933 16.3573 17.3757 17.213L18.1255 15.9138C16.4151 14.9266 14.475 14.4069 12.5002 14.4069L12.5002 15.9069ZM17.3757 17.213C18.8581 18.0686 20.0893 19.2993 20.9455 20.7813L22.2444 20.031C21.2565 18.321 19.836 16.901 18.1255 15.9138L17.3757 17.213ZM17.7501 9.15686C17.7501 12.0564 15.3996 14.4069 12.5002 14.4069V15.9069C16.2281 15.9069 19.2501 12.8848 19.2501 9.15686H17.7501ZM12.5002 14.4069C9.60065 14.4069 7.25005 12.0563 7.25005 9.15686H5.75005C5.75005 12.8848 8.77226 15.9069 12.5002 15.9069V14.4069ZM7.25005 9.15686C7.25005 6.25736 9.60056 3.90686 12.5001 3.90686V2.40686C8.77213 2.40686 5.75005 5.42894 5.75005 9.15686H7.25005ZM12.5001 3.90686C15.3995 3.90686 17.7501 6.25737 17.7501 9.15686H19.2501C19.2501 5.42894 16.228 2.40686 12.5001 2.40686V3.90686Z"
                            fill="#CCCCCC"
                        />
                    </svg>

                    <input
                        type="text"
                        placeholder="Last Name * "
                        className={'no-outline ps-4 text-dark fs-8'}
                        {...formik.getFieldProps('lastName')}
                    />
                </div>

                <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                        {formik.touched.lastName && formik.errors.lastName && formik.errors.lastName}
                    </div>
                </div>
            </div>

            <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                    {formik.values.phoneNumber.length !== 0 && (
                        <label className="required fw-bold fs-9">Phone Number</label>
                    )}
                </div>

                <div className={`form-control form-control-solid  d-flex`}>
                    <img src={phone} alt="fullName" className="icon-style" />
                    <NumericFormat
                        type="text"
                        className="no-outline ps-2 text-dark fs-8"
                        {...formik.getFieldProps('phoneNumber')}
                        placeholder=" Phone Number*"
                        allowNegative={false}
                        allowLeadingZeros={true}
                    />
                </div>

                <div className="fv-plugins-message-container input-height" style={{ color: 'red' }}>
                    <div className="fv-help-block fs-9">
                        {formik.touched.phoneNumber && formik.errors.phoneNumber && formik.errors.phoneNumber}
                    </div>
                </div>
            </div>

            <div className="mt-1">
                <div className="col-lg-4 col-form-label py-0 input-height">
                    {formik.values.email.length !== 0 && <label className="required fw-bold fs-9">Email</label>}
                </div>

                <div className="form-control form-control-solid  ">
                    <img src={envelope} alt="firstName" className="icon-style" />

                    <input
                        type="email"
                        placeholder="Email * "
                        className={'no-outline ps-4 text-dark fs-8'}
                        {...formik.getFieldProps('email')}
                    />
                </div>

                <div className="fv-plugins-message-container input-height">
                    <div className="fv-help-block fs-9" style={{ color: 'red' }}>
                        {formik.touched.email && formik.errors.email && formik.errors.email}
                    </div>
                </div>
            </div>

            <div className="mt-1">
                <div className="login-rem-grid-signup">
                    <div>
                        <input type="checkbox" placeholder="termsCondtion*" className="" onChange={termsCondtion} />

                        <span className="signup-remeber-text" style={{ marginLeft: '5px' }}>
                            I agree to the
                            <span className="step-text-link">
                                {' '}
                                <a href="/termsConditions" target="blank">
                                    {' '}
                                    terms and conditions
                                </a>{' '}
                            </span>
                            of use and
                            <span className="step-text-link">
                                {' '}
                                <a href="/privacyPolicy" target="blank">
                                    privacy policy
                                </a>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <nav className={`nav navbar-fixed-signup-bottom `} id="nav">
                <div className="nav-center-signup">
                    <div className="nav-header-signup">
                        <div></div>
                        <button
                            id="kt_password_cancel "
                            type="submit"
                            className={`btn nav-btn-Proceed ${
                                !(formik.isValid && formik.dirty && checked) ? 'btn-secondary' : 'btn-success'
                            } text-white px-7 py-2`}
                            disabled={!(formik.isValid && formik.dirty && checked)}
                            onClick={(e) => setConfirm(true)}
                        >
                            {!isLoading && <span className="indicator-label">Proceed</span>}
                            {isLoading && (
                                <span className="indicator-progress" style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </nav>
            {confrimModal()}
        </>
    );
};

export { StepOne };
